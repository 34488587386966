import Alpine from 'alpinejs';

// function animateIcon(icon) {
//   var container = icon;
//   var $iconContainer = $(container).find(".pd-iconsanimated__icon__container");
//   var $icons = $iconContainer.children();
//
//   var animation = TweenMax.staggerFrom($icons, 0.6, {
//     scale: 0,
//     autoalpha: 0,
//     x: 0,
//     y: 0,
//     rotation: 0,
//     ease:Back.easeOut.config(1.7)
//   }, 0.2);
// }

function init() {

  var $grid;

  var isotopeOptions = {
    itemSelector: '.case',
    layoutMode: 'fitRows',
    percentPosition: true,
    masonry: {
      columnWidth: '.case',
    },
    filter: function() {
      var isMatched = true;
      var $this = $(this);

      for ( var prop in filterFns ) {
        // test each filter
        var filterFn = filterFns[ prop ];
        isMatched = isMatched && $this.is( filterFn );
      }
      return isMatched;
    },
  };


  $grid = $('.grid').isotope(isotopeOptions);


// filter functions
  var filterFns = {
    // show if number is greater than 50
    numberGreaterThan50: function() {
      var number = $(this).find('.number').text();
      return parseInt( number, 10 ) > 50;
    },
    // show if name ends with -ium
    ium: function() {
      var name = $(this).find('.name').text();
      return name.match( /ium$/ );
    }
  };

  $('.filter__buttons__container').on( 'click', 'button', function() {
    console.log("clicky");
    var filterValue = $( this ).attr('data-filter');
    // use filterFn if matches value
    filterValue = filterFns[ filterValue ] || filterValue;
    $grid.isotope({ filter: filterValue });
  });


  //****************************
  // Isotope Load more button
  //****************************
  var initShow = 16; //number of items loaded on init & onclick load more button
  var counter = initShow; //counter for load more button
  var iso = $grid.data('isotope'); // get Isotope instance

  loadMore(initShow); //execute function onload

  function loadMore(toShow) {
    $grid.find(".hidden").removeClass("hidden");

    var hiddenElems = iso.filteredItems.slice(toShow, iso.filteredItems.length).map(function(item) {
      return item.element;
    });
    $(hiddenElems).addClass('hidden');
    $grid.isotope('layout');

    //when no more to load, hide show more button
    if (hiddenElems.length == 0) {
      jQuery("#load-more").hide();
    } else {
      jQuery("#load-more").show();
    };

  }

  //append load more button
  $grid.after('<div class="flex flex-row justify-center items-center mt-12"><button class="text-21 border border-white text-center text-white py-4 px-8 hover:bg-white hover:text-black" id="load-more">Load more</button></div>');

  //when load more button clicked
  $("#load-more").click(function() {
    if ($('#filters').data('clicked')) {
      //when filter button clicked, set initial value for counter
      counter = initShow;
      $('#filters').data('clicked', false);
    } else {
      counter = counter;
    };

    counter = counter + initShow;

    loadMore(counter);
  });

  //when filter button clicked
  $("#filters").click(function() {
    $(this).data('clicked', true);

    loadMore(initShow);
  });




  $(document).on('click', '#load-more-cases', function(e) {
    var caseCount = $(this).attr('data-case-count');
    var caseLimit = $(this).attr('data-case-limit');

    $('.cases__wrapper .case:lt(caseLimit)').css('opacity', '1.0');
    $('.cases__wrapper .case:lt(caseLimit)').removeClass('hidden');


    caseLimit = $('.cases__wrapper .case:visible').length + 8;

    if(caseLimit < caseCount) {
      $('.cases__wrapper .case:lt(' + caseLimit + ')').css('opacity', '1.0');
      $('.cases__wrapper .case:lt(' + caseLimit + ')').removeClass('hidden');

    }
    else {
      $('.cases__wrapper .case:lt('+ caseCount +')').css('opacity', '1.0');
      $('.cases__wrapper .case:lt('+ caseCount +')').removeClass('hidden');
      $('#load-more').addClass('hidden');
    }

    // $('.cases__wrapper').find('.case').css('opacity', '1.0');
    // $('.cases__wrapper').find('.case').removeClass('hidden');



    return false;
  });

  // var controller2 = new ScrollMagic.Controller();
  // $('.pd-iconsanimated__item').each(function(){
  //   var ourScene = new  ScrollMagic.Scene({
  //     triggerElement: this,
  //     triggerHook: 0.9,
  //     reverse: false,
  //     //duration: '100%',
  //   })
  //       .on('start', function () {
  //         animateIcon(this.triggerElement());
  //       })
  //       .setClassToggle(this, 'animate')
  //       // .addIndicators({
  //       // 	name: 'pd-fade-in',
  //       // 	colorTrigger: 'white',
  //       // 	colorStart: 'green',
  //       // 	colorEnd: 'red'
  //       // })
  //       .addTo(controller2);
  // });
  //
  // $('.pd-iconsanimated__item').mouseleave(function() {
  //   var container = this;
  //   var $iconContainer = $(container).find(".pd-iconsanimated__icon__container");
  //   var $icons = $iconContainer.children();
  //   $icons.each(function(index){
  //     $currentIcon = this;
  //     TweenMax.killTweensOf($currentIcon);
  //     TweenLite.set($currentIcon, {clearProps:"all"});
  //   });
  //   animateIcon(this);
  // });


  window.Alpine = Alpine;
  Alpine.start();

  $('a[href^="#"], button[href^="#"]').on('click', function(e) {
    e.preventDefault();

    const scrollTop = $($(this).attr('href')).position().top - 50;

    $('html, body').animate({ scrollTop });
  })
}

window.addEventListener("load", function () {
  init(); //
});
